import { gql } from "@apollo/client";

export const CREATE_REQUEST_MUTATION = gql`
  mutation CreateRequest($input: CreateRequestInput!) {
    createRequest(input: $input) {
      request {
        id
        status
        orderNumber
        readyForPayment
        stage
        createdAt
        updatedAt
        type
        totalAmount
        gclid
        preferredPaymentMethod {
          id
          card {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        trips {
          id
          note
          totalGroupSize
          tempPassenger {
            name
          }
          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            mobilePhoneInternational
            stripeCustomer
            paymentMethods {
              id
              stripeId
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
              isPrimary
            }
          }
          stops {
            id
            location
            dateTime
            groupSize
            note
          }
          routes {
            id
            publicId
            vehicle {
              id
              name
            }
          }
          returnTrip {
            id
            tempPassenger {
              name
            }
            contact {
              id
            }
          }
        }
        bookingContact {
          id
          firstName
          lastName
          email
          mobilePhone
          mobilePhoneInternational
          phoneCountryCode
          phoneCountryDialCode
          phoneCountryFormat
          phoneCountryName
          paymentMethods {
            id
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            isPrimary
          }
        }
      }
    }
  }
`;

export const CREATE_WHITE_LABEL_QUOTE = gql`
  mutation CreateWhiteLabelQuote($input: CreateWhiteLabelQuoteInput!) {
    createWhiteLabelQuote(input: $input) {
      request {
        id
      }
    }
  }
`;

export const LOAD_REQUEST_QUERY = gql`
  query Request($id: ID!) {
    node(id: $id) {
      id
      ... on Request {
        id
        orderNumber
        orderType
        readyForPayment
        stage
        status
        createdAt
        updatedAt
        cancelledAt
        isDeclined
        type
        totalAmount
        amountDue
        gclid
        preferredPaymentMethod {
          id
          card {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        bookingContact {
          id
          firstName
          lastName
          email
          mobilePhone
          mobilePhoneInternational
          phoneCountryCode
          phoneCountryDialCode
          phoneCountryFormat
          phoneCountryName
          stripeCustomer
          paymentMethods {
            id
            stripeId
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            isPrimary
          }
        }
        company {
          id
          name
          phoneNumber
          email
        }
        combinedSubPayments {
          id
          amount
          createdAt
          paymentId
          payment {
            id
            method
            note
            createdAt
            amount
            paymentMethod {
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
            }
          }
        }
        combinedRefunds {
          id
          amount
          method
          note
          createdAt
          payment {
            id
            paymentMethod {
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
            }
          }
        }
        trips {
          id
          totalDuration
          estimatedDuration
          useTotalDuration
          googleDirectionsResult
          estimatedDuration
          useTotalDuration
          totalDuration
          totalGroupSize
          createdAt
          updatedAt
          cancelledAt
          statusUpdateTextTo
          reminderTextTo
          requestId
          tripNumber
          note
          totalAmount
          amountDue
          temporaryPassenger
          tempPassenger {
            name
          }
          roundTripVariant
          tripCategory
          canAutomateCustomerChange
          childSeats
          billings {
            id
            amount
            note
            action
            createdAt
            updatedAt
          }

          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            mobilePhoneInternational
            stripeCustomer
            paymentMethods {
              id
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
              isPrimary
            }
          }
          stops {
            id
            createdAt
            updatedAt
            stopIndex
            variant
            dateTime
            location
            groupSize
            note
            operatorNote
            driverNote
            groupSize
            logisticStreetViewUrl
            flightNumber
            originalDateTime
            flightOffset
            pickUpVariant
            trackedFlight {
              id
              faFlightId
              updatedAt
              airline {
                airlineName
                iataCode
              }
              actualAirline {
                airlineName
                iataCode
              }
              flightStatus
              flightNumber
              actualFlightNumber
              origin {
                id
                airport {
                  airportName
                  iataCode
                  icaoCode
                  countryName
                  countryIso2
                  timezone
                  gmt
                }
                scheduledGate
                scheduledDateTime
                scheduledTerminal
                actualGate
                actualDateTime
                estimatedDateTime
                actualTerminal
              }
              destination {
                id
                airport {
                  airportName
                  iataCode
                  icaoCode
                  countryName
                  countryIso2
                  timezone
                  gmt
                }
                scheduledGate
                scheduledDateTime
                scheduledTerminal
                actualGate
                estimatedDateTime
                actualDateTime
                actualTerminal
              }
            }
            airport {
              icaoCode
              iataCode
              airportName
              countryName
              countryIso2
              timezone
              gmt
              coordinates
            }
            airline {
              icaoCode
              iataCode
              airlineName
              callsign
              fleetSize
              countryName
              countryIso2
              dateFounded
            }
            pickUpVariant
          }
          routes {
            id
            createdAt
            updatedAt
            publicId
            groupSize
            dispatchStatus
            isFarmedRoute
            farmRelationship
            operatorConfirmation
            carryOnLuggage
            checkedLuggage
            oversizeLuggage
            pricing {
              id
              baseRateAmt
              baseRateVariant
              hourlyBaseRateHours
              hourlyBaseRate
              driverGratuityAmt
              driverGratuityPercent
              promoDiscountAmt
              promoDiscountPercent
              taxAmt
              taxPercent
              tollsAmt
              meetGreetAmt
              otherName
              otherAmt
              otherPercent
              other2Name
              other2Amt
              other2Percent
              other3Name
              other3Amt
              other3Percent
              promoCodeId
              promoCodeName
              promoCodeAmt
              promoCodePercent
              cancellationFee
              totalAmt
              meetGreetAmt
              forwardFacingSeatQuantity
              forwardFacingSeatAmt
              rearFacingSeatQuantity
              rearFacingSeatAmt
              boosterSeatQuantity
              boosterSeatAmt
            }
            farmeePricing {
              id
              baseRateAmt
              driverGratuityAmt
              driverGratuityPercent
              promoDiscountAmt
              promoDiscountPercent
              taxAmt
              taxPercent
              tollsAmt
              otherName
              otherAmt
              otherPercent
              other2Name
              other2Amt
              other2Percent
              other3Name
              other3Amt
              promoCodeId
              promoCodeName
              promoCodeAmt
              promoCodePercent
              totalAmt
              meetGreetAmt
              forwardFacingSeatQuantity
              forwardFacingSeatAmt
              rearFacingSeatQuantity
              rearFacingSeatAmt
              boosterSeatQuantity
              boosterSeatAmt
            }
            farmAffiliate {
              ... on ExternalOperator {
                id
                operatorName
                operatorEmail
                operatorPhone
              }
              ... on MoovsNetworkOperator {
                id
                operatorName
                operatorEmail
                operatorPhone
              }
              ... on GriddnetOperator {
                id
                operatorName
                operatorEmail
                operatorPhone
              }
              ... on MoovsAiOperator {
                id
                operatorName
                operatorEmail
                operatorPhone
              }
            }
            vehicle {
              id
              capacity
              baseRateAutomation {
                total
                lineItems {
                  name
                  description
                  lineTotal
                  source
                }
              }
              description
              name
              available
              licensePlate
              weekendHourlyCost
              weekdayHourlyCost
              weekendMinMinutes
              vehicleType {
                typeName
                typeSlug
              }
              weekdayMinMinutes
              operator {
                id
                name
              }
              features {
                id
                name
                category
              }
              photos {
                id
                url
                photoIndex
              }
              vehicleType {
                typeName
                typeSlug
              }
              settings {
                id
                forwardFacingSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
                rearFacingSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
                boosterSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
              }
            }
            driver {
              id
              firstName
              lastName
              mobilePhone
            }
          }
          returnTrip {
            id
            totalDuration
            estimatedDuration
            useTotalDuration
            googleDirectionsResult
            estimatedDuration
            useTotalDuration
            totalDuration
            totalGroupSize
            createdAt
            updatedAt
            cancelledAt
            requestId
            tripNumber
            note
            totalAmount
            amountDue
            temporaryPassenger
            roundTripVariant
            tripCategory
            canAutomateCustomerChange
            childSeats
            billings {
              id
              amount
              note
              action
              createdAt
              updatedAt
            }
            contact {
              id
              firstName
              lastName
              email
              mobilePhone
              mobilePhoneInternational
              stripeCustomer
              paymentMethods {
                id
                card {
                  id
                  expMonth
                  expYear
                  last4
                  brand
                }
                isPrimary
              }
            }
            stops {
              id
              createdAt
              updatedAt
              stopIndex
              variant
              dateTime
              location
              groupSize
              note
              operatorNote
              driverNote
              groupSize
              logisticStreetViewUrl
              flightNumber
              originalDateTime
              flightOffset
              pickUpVariant
              trackedFlight {
                id
                faFlightId
                updatedAt
                airline {
                  airlineName
                  iataCode
                }
                actualAirline {
                  airlineName
                  iataCode
                }
                flightStatus
                flightNumber
                actualFlightNumber
                origin {
                  id
                  airport {
                    airportName
                    iataCode
                    icaoCode
                    countryName
                    countryIso2
                    timezone
                    gmt
                  }
                  scheduledGate
                  scheduledDateTime
                  scheduledTerminal
                  actualGate
                  actualDateTime
                  estimatedDateTime
                  actualTerminal
                }
                destination {
                  id
                  airport {
                    airportName
                    iataCode
                    icaoCode
                    countryName
                    countryIso2
                    timezone
                    gmt
                  }
                  scheduledGate
                  scheduledDateTime
                  scheduledTerminal
                  actualGate
                  estimatedDateTime
                  actualDateTime
                  actualTerminal
                }
              }
              airport {
                icaoCode
                iataCode
                airportName
                countryName
                countryIso2
                timezone
                gmt
                coordinates
              }
              airline {
                icaoCode
                iataCode
                airlineName
                callsign
                fleetSize
                countryName
                countryIso2
                dateFounded
              }
            }
            routes {
              id
              createdAt
              updatedAt
              publicId
              groupSize
              isFarmedRoute
              farmRelationship
              operatorConfirmation
              carryOnLuggage
              checkedLuggage
              oversizeLuggage
              pricing {
                id
                baseRateAmt
                driverGratuityAmt
                driverGratuityPercent
                promoDiscountAmt
                promoDiscountPercent
                taxAmt
                taxPercent
                tollsAmt
                meetGreetAmt
                otherName
                otherAmt
                otherPercent
                other2Name
                other2Amt
                other2Percent
                other3Name
                other3Amt
                other3Percent
                promoCodeId
                promoCodeName
                promoCodeAmt
                promoCodePercent
                cancellationFee
                totalAmt
                forwardFacingSeatQuantity
                forwardFacingSeatAmt
                rearFacingSeatQuantity
                rearFacingSeatAmt
                boosterSeatQuantity
                boosterSeatAmt
              }
              farmeePricing {
                id
                baseRateAmt
                driverGratuityAmt
                driverGratuityPercent
                promoDiscountAmt
                promoDiscountPercent
                taxAmt
                taxPercent
                tollsAmt
                otherName
                otherAmt
                otherPercent
                other2Name
                other2Amt
                other2Percent
                other3Name
                other3Amt
                other3Percent
                promoCodeId
                promoCodeName
                promoCodeAmt
                promoCodePercent
                totalAmt
                meetGreetAmt
                forwardFacingSeatQuantity
                forwardFacingSeatAmt
                rearFacingSeatQuantity
                rearFacingSeatAmt
                boosterSeatQuantity
                boosterSeatAmt
              }
              farmAffiliate {
                ... on ExternalOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
                ... on MoovsNetworkOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
                ... on GriddnetOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
                ... on MoovsAiOperator {
                  id
                  operatorName
                  operatorEmail
                  operatorPhone
                }
              }
              vehicle {
                id
                capacity
                baseRateAutomation {
                  total
                  lineItems {
                    name
                    description
                    lineTotal
                    source
                  }
                }
                description
                name
                available
                licensePlate
                weekendHourlyCost
                weekdayHourlyCost
                weekendMinMinutes
                weekdayMinMinutes
                operator {
                  id
                  name
                }
                features {
                  id
                  name
                  category
                }
                photos {
                  id
                  url
                  photoIndex
                }
                vehicleType {
                  typeName
                  typeSlug
                }
                settings {
                  id
                  forwardFacingSeat {
                    id
                    type
                    active
                    quantity
                    amt
                    imageUrl
                    description
                  }
                  rearFacingSeat {
                    id
                    type
                    active
                    quantity
                    amt
                    imageUrl
                    description
                  }
                  boosterSeat {
                    id
                    type
                    active
                    quantity
                    amt
                    imageUrl
                    description
                  }
                }
              }
              driver {
                id
                firstName
                lastName
                mobilePhone
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_RESERVATION_MUTATION = gql`
  mutation CreateReservation($input: CreateReservationInput!) {
    createReservation(input: $input) {
      request {
        orderNumber
        orderType
        id
        readyForPayment
        stage
        createdAt
        updatedAt
        type
        totalAmount
        gclid
        preferredPaymentMethod {
          id
          card {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        bookingContact {
          id
          firstName
          lastName
          email
          mobilePhone
          mobilePhoneInternational
          phoneCountryCode
          phoneCountryDialCode
          phoneCountryFormat
          phoneCountryName
          stripeCustomer
          paymentMethods {
            id
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            isPrimary
          }
        }
        trips {
          id
          totalDuration
          estimatedDuration
          useTotalDuration
          googleDirectionsResult
          estimatedDuration
          useTotalDuration
          totalDuration
          totalGroupSize
          createdAt
          updatedAt
          requestId
          tripNumber
          note
          totalAmount
          amountDue
          tempPassenger {
            name
          }
          billings {
            id
            amount
            note
            action
            createdAt
            updatedAt
          }
          subPayments {
            id
            amount
            payment {
              id
              method
              paymentMethod {
                card {
                  last4
                }
              }
              note
              amount
              action
              paymentStatus
              createdAt
              updatedAt
            }
          }
          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            mobilePhoneInternational
            stripeCustomer
            paymentMethods {
              id
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
              isPrimary
            }
          }
          stops {
            id
            createdAt
            updatedAt
            stopIndex
            variant
            dateTime
            location
            groupSize
            note
            operatorNote
            driverNote
            groupSize
            logisticStreetViewUrl
            flightNumber
            trackedFlight {
              id
              faFlightId
              updatedAt
              airline {
                airlineName
                iataCode
              }
              actualAirline {
                airlineName
                iataCode
              }
              flightStatus
              flightNumber
              actualFlightNumber
              origin {
                id
                airport {
                  airportName
                  iataCode
                  icaoCode
                  countryName
                  countryIso2
                  timezone
                  gmt
                }
                scheduledGate
                scheduledDateTime
                scheduledTerminal
                actualGate
                actualDateTime
                estimatedDateTime
                actualTerminal
              }
              destination {
                id
                airport {
                  airportName
                  iataCode
                  icaoCode
                  countryName
                  countryIso2
                  timezone
                  gmt
                }
                scheduledGate
                scheduledDateTime
                scheduledTerminal
                actualGate
                estimatedDateTime
                actualDateTime
                actualTerminal
              }
            }
            airport {
              icaoCode
              iataCode
              airportName
              countryName
              countryIso2
              timezone
              gmt
              coordinates
            }
            airline {
              icaoCode
              iataCode
              airlineName
              callsign
              fleetSize
              countryName
              countryIso2
              dateFounded
            }
          }
          routes {
            id
            createdAt
            updatedAt
            publicId
            groupSize
            pricing {
              forwardFacingSeatAmt
              rearFacingSeatAmt
              boosterSeatAmt
            }
            operatorConfirmation
            vehicle {
              id
              capacity
              description
              name
              available
              licensePlate
              baseRateAutomation {
                total
                lineItems {
                  name
                  description
                  lineTotal
                  source
                }
              }
              weekendHourlyCost
              weekdayHourlyCost
              weekendMinMinutes
              weekdayMinMinutes
              operator {
                id
                name
              }
              features {
                id
                name
                category
              }
              photos {
                id
                url
                photoIndex
              }
              vehicleType {
                typeName
                typeSlug
              }
            }
            driver {
              id
              firstName
              lastName
              mobilePhone
            }
          }
        }
      }
    }
  }
`;

export const CREATE_RESERVATION_PAYMENT_MUTATION = gql`
  mutation CreateReservationPayment($input: CreateReservationPaymentInput!) {
    createReservationPayment(input: $input) {
      payment {
        id
      }
    }
  }
`;

export const CONFIRM_REQUEST_MUTATION = gql`
  mutation ConfirmRequest($input: ConfirmRequestInput!) {
    confirmRequest(input: $input) {
      request {
        orderNumber
        orderType
        id
        readyForPayment
        stage
        createdAt
        updatedAt
        type
        totalAmount
        gclid
        preferredPaymentMethod {
          id
          card {
            id
            brand
            last4
            expMonth
            expYear
          }
        }
        bookingContact {
          id
          firstName
          lastName
          email
          mobilePhone
          mobilePhoneInternational
          phoneCountryCode
          phoneCountryDialCode
          phoneCountryFormat
          phoneCountryName
          stripeCustomer
          paymentMethods {
            id
            card {
              id
              expMonth
              expYear
              last4
              brand
            }
            isPrimary
          }
        }
        trips {
          id
          totalDuration
          useTotalDuration
          googleDirectionsResult
          estimatedDuration
          useTotalDuration
          totalDuration
          totalGroupSize
          createdAt
          updatedAt
          requestId
          tripNumber
          note
          totalAmount
          amountDue
          tempPassenger {
            name
          }
          billings {
            id
            amount
            note
            action
            createdAt
            updatedAt
          }
          subPayments {
            id
            amount
            payment {
              id
              method
              paymentMethod {
                card {
                  last4
                }
              }
              note
              amount
              action
              paymentStatus
              createdAt
              updatedAt
            }
          }
          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            mobilePhoneInternational
            stripeCustomer
            paymentMethods {
              id
              card {
                id
                expMonth
                expYear
                last4
                brand
              }
              isPrimary
            }
          }
          stops {
            id
            createdAt
            updatedAt
            stopIndex
            variant
            dateTime
            location
            groupSize
            note
            operatorNote
            driverNote
            groupSize
            logisticStreetViewUrl
            flightNumber
            trackedFlight {
              id
              faFlightId
              updatedAt
              airline {
                airlineName
                iataCode
              }
              actualAirline {
                airlineName
                iataCode
              }
              flightStatus
              flightNumber
              actualFlightNumber
              origin {
                id
                airport {
                  airportName
                  iataCode
                  icaoCode
                  countryName
                  countryIso2
                  timezone
                  gmt
                }
                scheduledGate
                scheduledDateTime
                scheduledTerminal
                actualGate
                actualDateTime
                estimatedDateTime
                actualTerminal
              }
              destination {
                id
                airport {
                  airportName
                  iataCode
                  icaoCode
                  countryName
                  countryIso2
                  timezone
                  gmt
                }
                scheduledGate
                scheduledDateTime
                scheduledTerminal
                actualGate
                estimatedDateTime
                actualDateTime
                actualTerminal
              }
            }
            airport {
              icaoCode
              iataCode
              airportName
              countryName
              countryIso2
              timezone
              gmt
              coordinates
            }
            airline {
              icaoCode
              iataCode
              airlineName
              callsign
              fleetSize
              countryName
              countryIso2
              dateFounded
            }
          }
          routes {
            id
            createdAt
            updatedAt
            publicId
            groupSize
            pricing {
              baseRateAmt
              hourlyBaseRateHours
              driverGratuityAmt
              driverGratuityPercent
              promoDiscountAmt
              promoDiscountPercent
              taxAmt
              taxPercent
              tollsAmt
              meetGreetAmt
              otherName
              otherAmt
              otherPercent
              other2Name
              other2Amt
              other2Percent
              other3Name
              other3Amt
              other3Percent
              promoCodeId
              promoCodeName
              promoCodeAmt
              promoCodePercent
              forwardFacingSeatQuantity
              forwardFacingSeatAmt
              rearFacingSeatQuantity
              rearFacingSeatAmt
              boosterSeatQuantity
              boosterSeatAmt
            }
            operatorConfirmation
            vehicle {
              id
              capacity
              description
              name
              available
              licensePlate
              baseRateAutomation {
                total
                lineItems {
                  name
                  description
                  lineTotal
                  source
                }
              }
              weekendHourlyCost
              weekdayHourlyCost
              weekendMinMinutes
              weekdayMinMinutes
              operator {
                id
                name
              }
              features {
                id
                name
                category
              }
              photos {
                id
                url
                photoIndex
              }
              vehicleType {
                typeName
                typeSlug
              }
              settings {
                id
                forwardFacingSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
                rearFacingSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
                boosterSeat {
                  id
                  type
                  active
                  quantity
                  amt
                  imageUrl
                  description
                }
              }
            }
            driver {
              id
              firstName
              lastName
              mobilePhone
            }
          }
        }
      }
    }
  }
`;

export const LOAD_REQUESTS_QUERY = gql`
  query LoadRequests($timeFrame: RequestTimeFrameEnum, $stage: RequestStage) {
    loadRequests(timeFrame: $timeFrame, stage: $stage) {
      edges {
        node {
          id
          orderNumber
          type
          totalAmount
          cancelledAt
          activeTripsCount
          amountDue
          stage
          isDeclined
          preferredPaymentMethod {
            id
            card {
              id
              brand
              last4
              expMonth
              expYear
            }
          }
          trips {
            id
            cancelledAt
            stops {
              dateTime
            }
            routes {
              publicId
              vehicle {
                id
                name
              }
              pricing {
                baseRateAmt
              }
            }
          }
        }
      }
    }
  }
`;

export const ADD_TRIP_TO_QUOTE = gql`
  mutation AddTripToQuote($input: AddTripToQuoteInput!) {
    addTripToQuote(input: $input) {
      request {
        id
        bookingContact {
          id
        }
        trips {
          id
          tempPassenger {
            name
          }
          contact {
            id
          }
        }
      }
    }
  }
`;

export const LOAD_TRIP_CONFLICT_QUERY = gql`
  query loadTripConflict($requestId: ID!) {
    loadTripConflict(requestId: $requestId)
  }
`;
